export default class Model {

    public uid: string;
    public name: string;
    public label: string;
    public data: any;
    public modelProperties: any;
    public created: Date;
    public modified: Date;
    public status: string;

    constructor(uid: string, name: string, label: string, data: any, modelProperties: any,
                created: Date, modified: Date, status: string) {
        this.uid = uid;
        this.name = name;
        this.label = label;
        this.data = data;
        this.modelProperties = modelProperties;
        this.created = created;
        this.modified = modified;
        this.status = status;
    }
}