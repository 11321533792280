import React, { Component, useEffect, useState } from 'react';
import SystemHandler from '../../handlers/SystemHandler';
import System from '../../models/System';

export default function DataTable(props: any) {

    const data: any = (props.CurrentSystem.Data) ? props.CurrentSystem?.Data?.map((r: any, i: any) => {
        return JSON.parse(window.atob(r.contentB64));
    }) : false;

    console.log("Data table with data ", data);

    useEffect(() => {
        SystemHandler.logEvent("PADMIN useEffect()", "Calling useEffect() by props.CurrentSystem", props.CurrentSystem);
        let currentSystem = SystemHandler.getCurrentState();
        if (currentSystem.Data.lenght !== props.CurrentSystem.Data.length)
            props.HandleLoadData();
    }, [props.CurrentSystem]);

    return (
        <table id="main-data-table" className="table table-hover table-responsive table-sm table-striped">
            <thead className="bg-dark text-light">
                <tr>
                    {props.CurrentSystem.SelectedModel?.modelProperties.map((r: any, i: any) => {
                        return (!r.isHidden) ? <td key={r.name}><small>{r.name.toUpperCase()}</small></td> : false;
                    })}
                </tr>
            </thead>
            <tbody>
                {(data) ? data.map((r: any, i: any) => {
                    return <tr key={i}>
                        {Object.entries(r[0]).map((key: any, index: any) => {
                            return <td key={index}><small>{key[1]}</small></td>
                        })}
                    </tr>
                }) : false}
                <tr>
                    <td>
                        <button className="btn btn-success p-1 m-1"
                            onClick={props.HandleLoadData}>
                            Refresh
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}


