import Guid from "../toolkit/Guid";
import Region from "./Region";
import User from "./User";

export default class Session {
    UID: string;
    CurrentUser!: User;
    West: Region;
    Data!: [];
    
    constructor(uid: string, currentUser: User, data: []) {
        this.UID = uid;
        this.CurrentUser = currentUser;
        this.Data = data;
        this.West = new Region(true);
    }

    public isValid() {
        let isValid = this.UID !== "00000000-0000-0000-0000-000000000000";
        isValid = isValid && (this.CurrentUser ?.username.length > 0);

        return isValid;
    }
}