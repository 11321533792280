import React, { Component } from 'react';
import SessionHandler from './SessionHandler';
import ServiceHandler from './ServiceHandler';
import SystemHandler from './SystemHandler';

export default class DataHandler {
    public Sid: string;
    public Username: string;
    public Password: string;
    public Data: object;
    //private session: SessionHandler;

    constructor(username: string, password: string, data: object, sid: string) {
        this.Sid = sid;
        this.Username = username;
        this.Password = password;
        this.Data = data;
        //this.session = new SessionHandler(sid, username, password, data);
    }

    static async SetData(ownerID: string, model: string, mainSearch: string, mainSearchLenght: number,
        additionalSearch: string, additionalSearchLenght: number, isSearchable: boolean, status: number,
        contentB64: string, contentLenght: number, contentSizeMB: number, order: number, sid: string) {

        SystemHandler.logEvent("SetData in " + model, "Setting new data in " + model + " by " + ownerID, contentB64);

        fetch("https://eacore.azurewebsites.net/Data/SetData" +
            "?ownerUID=" + ownerID + 
            "&model=" + model +
            "&mainSearch=" + mainSearch +
            "&mainSearchLenght=" + mainSearchLenght +
            "&additionalSearch=" + additionalSearch +
            "&additionalSearchLenght=" + additionalSearchLenght +
            "&isSearchable=" + isSearchable +
            "&status=" + status +
            "&contentLenght=" + contentLenght +
            "&contentSizeMB=" + contentSizeMB +
            "&order=" + order +
            "&sid=" + sid, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: "\"" + contentB64 + "\""
        }).then((response) => {
            console.log("SetData Response", response);
        });
    }
}