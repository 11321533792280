import System from '../models/System';
import SystemHandler from './SystemHandler';

export default class ServiceHandler {
    public static loadModel(currentSystem: System, modelName: string) {
        let host: any = System.GetConfigurationByName("host");
        let sid: string = currentSystem?.Session?.UID ?? "ca052603-b41a-411a-957a-572207e09cbe";
        let url: string = host + '/Models/GetModelByName?name=' + modelName + '&sid=' + sid;

        SystemHandler.logEvent("ServiceHandler > loadModel", "fetching " + url, currentSystem);

        fetch(url,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                //signal: abortController.signal,
            },).then(async response => response.json())
            .then(response => {
                let system: System = SystemHandler.getCurrentState();

                if (response.error) {
                    SystemHandler.logEvent("ServiceHandler > loadModel", "ERROR fetching " + url, [system, response]);
                    return Promise.reject();
                } else {
                    let model = response;
                    system.SelectedModel = model;

                    let isValid = true;
                    system.Models.forEach(m => {
                        if (model.name === m.name)
                            isValid = false;
                    });
                    if (isValid)
                        system.Models.push(response);

                    SystemHandler.setCurrentState(system);
                }
            });
    }
}
