import React, { Component, useEffect, useMemo, useState } from 'react';
import SystemHandler from './handlers/SystemHandler';
import SessionHandler from './handlers/SessionHandler';
import System from './models/System';
import Login from './components/Login/Login';
import Padmin from './components/Padmin/Padmin';
import './App.css';
import User from './models/User';
import Session from './models/Session';
import ServiceHandler from './handlers/ServiceHandler';
import Clock from './components/Attendance/Clock';

function App() {
    let system: System = SystemHandler.getCurrentState();

    const [lastUpdate, setLastUpdate] = useState(new Date());
    const [currentSystem, setCurrentSystem] = useState(system);
    const [doReload, setDoReload] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search)
    const module = queryParameters.get("module")

    const handleSystemUpdate = (action: string, system: System, modelName: string = "") => {
        if (action.indexOf("store") >= 0) {
            setLastUpdate(system.Updated);
            setCurrentSystem(system);
            setLastUpdate(new Date());
            return true;
        }
        if (action.indexOf("load") >= 0) {
            console.log("Calling " + action + " of " + modelName, new Date());
            ServiceHandler.loadModel(system, modelName);
            setTimeout(() => { setLastUpdate(new Date()) }, 1000);
            setDoReload(true);
            return true;
        }
        if (action.indexOf("refresh") >= 0) {
            setDoReload(true);
            return true;
        }
        if (action.indexOf("logout") >= 0) {
            SystemHandler.logout();
            return true;
        }
        if (action.indexOf("save") >= 0) {
            SystemHandler.setCurrentState(system)
            return true;
        }
        else
            return false;
    };

    useEffect(() => {
        if (doReload) {
            setDoReload(false);
        }
        else {
            let system: System = SystemHandler.getCurrentState();
            system.Updated = lastUpdate;
            setCurrentSystem(system);
        }

    }, [lastUpdate, doReload]);

    return (
        <div className="container-fluid p-0">
            {(module?.toLowerCase() === "clock") ? <Clock />
                : (SessionHandler.isValidSession(currentSystem.Session) ?
                    <Padmin CurrentSystem={currentSystem}
                        HandleSystemUpdate={handleSystemUpdate}
                        DoReload={doReload} />
                    : <Login />)}
        </div>
    );
}

export default App;
