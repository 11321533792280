export default class User {
    public pid!: string;
    public uid!: string;
    public username!: string;
    public pmd5!: string;
    public data!: any;
    public created!: Date;
    public modified!: Date;
    public status!: Date;
    public permissions!: [];

    constructor(pid: string, uid: string, username: string, pmd5: string, data: any,
        created: Date, modified: Date, status: Date, permissions: []) {
        this.pid = pid;
        this.uid = uid;
        this.username = username;
        this.pmd5 = pmd5;
        this.data = data;
        this.created = created;
        this.modified = modified;
        this.status = status;
        this.permissions = permissions;
    }
}