import React, { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faHelmetSafety } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import SystemHandler from '../../handlers/SystemHandler';
import System from '../../models/System';
import DataHandler from '../../handlers/DataHandler';

export default function Clock(props: any) {
    const [date, setDate] = useState(new Date());
    const timerId = setInterval(refreshClock, 1000);
    function refreshClock() {
        setDate(new Date());
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };

    }, [props.CurrentSystem]);

    return (
        <div className="container p-1">
            <div className="row p-1">
                <div className="offset-4 col-sm-4 p-1 text-center">
                    <span className="text-white p-1"> <FontAwesomeIcon icon={faClock} /> </span>
                    <span id="name-span" className="text-light font-bold">
                        CLOCK
                    </span>
                    <br />
                    <span id="time" className="text-white">{date.toUTCString()}</span>
                </div>
            </div>
            <div className="row p-1">
                <div className="offset-4 col-sm-4 p-1">
                    <img id="employee-image" className="rounded border border-5 border-light" width="100%" src="https://cdn5.vectorstock.com/i/1000x1000/06/04/clock-people-time-user-icon-vector-20080604.jpg" />
                </div>
            </div>
            <div className="row p-1">
                <div className="offset-4 col-sm-4 p-1">
                    <input id="employee-id" type="text" className="form-control text-center rounded border border-3 border-light" placeholder="#####" />
                </div>
            </div>
            <div className="row p-1">
                <div className="offset-4 col-sm-4 p-1">
                    <button className="btn btn-primary w-100" onClick={(e) => {
                        e.preventDefault();
                        let system: System = SystemHandler.getCurrentState();
                        let host: string = System.GetConfigurationByName("host");
                        let sid: string = system?.Session?.UID;
                        let url: string = host + '/Data/GetData'
                            + '?ownerUID=' + system.Session.CurrentUser.uid
                            + '&model=EMPLOYEE'
                            + '&mainSearch=' + $("#employee-id").val()
                            + '&additionalSearch=[na]'
                            + '&page=' + 1
                            + '&pageSize=' + 100
                            + '&sid=' + sid;

                        SystemHandler.logEvent("clock > onSubmit ", "Fetching /Data/GetData ...", props.CurrentSystem);

                        try {
                            fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
                                .then(response => response.json())
                                .then(async response => {
                                    if (response.error) {
                                        alert(response.error);
                                        return false;
                                    }

                                    $("#employee-id").val("");
                                    $("#employee-image").removeClass("border-light");

                                    let dataFound = response[0];
                                    console.log("Found data: ", dataFound);
                                    if (dataFound) {
                                        let employees = JSON.parse(window.atob(dataFound.contentB64));
                                        if (employees.length > 0) {
                                            DataHandler.SetData(system.Session.CurrentUser.uid,
                                                'ATTENDANCE', employees[0].EmployeeCode,
                                                employees[0].EmployeeCode.length, '[na]', 0,
                                                true, 1, dataFound.contentB64, dataFound.contentB64.length,
                                                dataFound.contentB64.length, 1, sid).then(response => {
                                                    console.log("Adding new attendance: ", response);
                                                });

                                            $("#name-span").html("Welcome <br />" + employees[0].EmployeeName + " " + employees[0].EmployeeLastName);
                                            $("#employee-image").addClass("border-success");
                                        } else {
                                            $("#name-span").html("NOT FOUND <br /> UNKNOWN");
                                            $("#employee-image").addClass("border-danger");
                                        }
                                    } else {
                                        $("#name-span").html("NOT FOUND <br /> UNKNOWN");
                                        $("#employee-image").addClass("border-danger");
                                    }



                                    return true;
                                });

                        } catch (e) {
                            SystemHandler.logEvent("FETCH ERROR: ", "Error /Data/GetData", e);
                            return false;
                        }

                        setTimeout(() => {
                            $("#name-span").html("CLOCK");
                            $("#employee-image").addClass("border-light");
                            $("#employee-image").removeClass("border-success");
                            $("#employee-image").removeClass("border-danger");
                            $("#employee-id").focus();
                        }, 2000);
                    }}>
                        Lets rock! <FontAwesomeIcon icon={faHelmetSafety} />
                    </button>
                </div>
            </div>
        </div>
    )
}


