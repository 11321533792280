import React, { Component, useEffect, useState } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import './Padmin.css';
import SystemHandler from '../../handlers/SystemHandler';
import Model from '../../models/Model';
import System from '../../models/System';

export default function PadminForm(props: any) {
    const [fields, setFields] = useState([]);

    const handleLoad = () => {
        let system: System = SystemHandler.getCurrentState();
        let activeProperties: any = system.SelectedModel.modelProperties;
        let result: any = activeProperties.map((property: any, index: number) =>
            <div key={property.id} className="row p-1">
                <div className="col-sm">
                    <label htmlFor={property.name + "input"} hidden={property.isHidden}>{index + ". " + property.label}:</label>
                    <input id={property.name + "input"}
                        type={property.type.toString()}
                        placeholder={property.name}
                        readOnly={property.isReadOnly}
                        hidden={property.isHidden}
                        className={"form-control " + property.cssClass}
                        pattern={property.regExToValidate}
                        onBlur={(e) => {
                            e.preventDefault();
                            console.log("New value detected",)

                            let value = e.target.value;

                            if (property.isMainSearch) {
                                props.MainSearchHandler(value);
                                props.AdditionalSearchHandler(value);
                            } else {
                                if (property.isIndex)
                                    props.AdditionalSearchHandler(value);
                            }

                            console.log("Encoded data is:", props.NewData);

                            let newData = [];
                            let newObject: any = props.NewRecord;
                            newObject[property.name] = value;
                            newData.push(newObject);
                            props.NewDataHandler(window.btoa(JSON.stringify(newData)));
                        }}
                    />
                </div>
            </div>
        );

        setFields(result);
        return result?? "";
    };

    useEffect(() => {
        let currentSystem = SystemHandler.getCurrentState();
        SystemHandler.logEvent("PadminForm calling useEffect()", "Fired by [fields]", currentSystem);
        if (currentSystem.Data.lenght !== props.CurrentSystem.Data.length)
            handleLoad();
    }, [props.CurrentSystem]);

    return (
        <div className="container-fluid bg-light mh-100">
            <div className="row">
                <button className="btn btn-success"
                    onClick={handleLoad}
                >Load</button>
            </div>

            {fields}
        </div>
    )
}