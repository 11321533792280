import ReactDOM from "react-dom";
import Session from "../models/Session";
import SystemHandler from "../handlers/SystemHandler";
import User from "../models/User";

export default class SessionHandler {

    static async create(username: string, password: string) {
        fetch('https://eacore.azurewebsites.net/Session/CreateNewSession?username=' + username + '&password=' + password,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    alert(response.error);
                    return false;
                }
                console.log("Result:", response);
            });
    }

    static isValidSession(session: any) {
        if (session !== undefined && session !== null)
            if (session.UID !== undefined && session.UID !== null && session.UID !== "00000000-0000-0000-0000-000000000000")
                return true;
            else {
                console.log("Nice try! ... but no, bad session.")
                return false;
            }
    }

    static async destroy() {
        console.log("Calling destroy method on the API ...");
    }

        //localStorage.Session = JSON.stringify(response);
        //localStorage.SID = response.uid;
        //console.log("New session: ", response);

        //let newUser = new User("", "", username, password, {}, new Date(), new Date(), new Date(), []);
        //let newSession = new Session("00000000-0000-0000-0000-000000000000", newUser, []);


        //if (response.uid) {
        //    let x = fetch('https://paneladministrativo.ddns.net/Component/GetComponent?name=demo&sid=' + localStorage.SID,
        //        {
        //            method: 'POST',
        //            headers: { 'Content-Type': 'application/json' }
        //        }).then(response => response.json())
        //        .then(response => {
        //            if (response.error) {
        //                alert(response.error);
        //                return false;
        //            }

        //            console.log("Loading demo component: ", response);
        //            localStorage.FormConfig = JSON.stringify(response);
        //            window.location.reload();
        //        }
        //        );
        //


        //let url = "/api/session.php";

        //const json = {
        //    action: "CREATE",
        //    username: username,
        //    password: password
        //};

        //const response = await fetch(url, {
        //    method: 'POST',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(json)
        //}).then(response => response.json())
        //    .then(data => {
        //        if (data.error) {
        //            alert(data.message);
        //        }

        //        if (localStorage.Debug === '1')
        //            console.log("url:", url, "Posted: ", data);

        //        if (data) {
        //            localStorage.SID = data.sid ?? "00000000-0000-0000-0000-000000000000";
        //        }
        //    });
}