import Model from "../models/Model";
import Session from "../models/Session";
import System from "../models/System";
import User from "../models/User";

export default class SystemHandler {

    static login(username: string, password: string): void {
        console.log("Calling login -> Fetch ..."); 
        const host = System.GetConfigurationByName("host");
        fetch(host + '/Session/CreateNewSession?username=' + username + '&password=' + password,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response.json())
            .then(response => {

                if (response.error) {
                    alert(response.error);
                    return false;
                }

                let system = this.getCurrentState();
                let newSession = new Session(response.uid, response.user, response.dataB64);
                system.Session = newSession;
                system.Updated = new Date();
                this.setCurrentState(system);
                system = this.getCurrentState();
                window.location.reload();
            });
    }

    static setCurrentState(system: any): void {
        if (system) {
            try {
                system.Updated = new Date().setSeconds(new Date().getSeconds() + 2);
                let value = JSON.stringify(system);
                sessionStorage.setItem("EASystemState", value);
                SystemHandler.logEvent("UPDATED", "Updated system is", system);

            } catch (e) {
                SystemHandler.logEvent("ERROR on UPDATE", "Oh man! - There was an error:", e);
            }
        }
    }

    static getCurrentState(): System {
        let result = new System(new Session("", new User("", "", "", "", "", new Date(), new Date(), new Date(), []), []));

        let systemString = sessionStorage.getItem("EASystemState");
        if (systemString) {
            let system = JSON.parse(systemString);
            if (system)
                result = system;
            return result;
        } else {
            return result;
        }
    }

    static getCurrentStateString(): string {
        return sessionStorage.getItem("EASystemState") ?? "";
    }


    static logEvent(title:string, message:string, args: any):void {
        if (localStorage.Debug == 1) {
            console.log("**   Title: " + title);
            console.log("** Message: " + message);
            console.log("**  [args]: ", args);
        }
    }

    static checkIsWestVisible() {
        let system = this.getCurrentState();
        return system.Session.West.IsVisible;
    }

    static toggleWestVisible() {
        let system = this.getCurrentState();
        system.Session.West.IsVisible = !system.Session.West.IsVisible;
        this.setCurrentState(system);
    }

    static addModelToState(model: Model): Model {
        let result = new Model("", "", "", null, null, new Date(), new Date(), "");
        let system = SystemHandler.getCurrentState();
        console.log("Storing ", system);
        system.Models.push(model);
        system.Updated = new Date();
        SystemHandler.setCurrentState(system);
        return result;
    }

    static update() {
        let system = this.getCurrentState();
        system.Updated = new Date();
        this.setCurrentState(system);
    }

    static logout() {
        sessionStorage.clear();
        window.location.reload();
    }
}