import Model from "./Model";
import Session from "./Session";
import Configuration from "./Configuration";
import SystemHandler from "../handlers/SystemHandler";

export default class System {
    public Session!: Session;
    public SelectedModel: Model = new Model("", "", "", [], [], new Date(), new Date(), ""); 
    public Models: Model[] = new Array<Model>(); 
    public MainSearch: string = ""; 
    public AdditionalSearch: string = ""; 
    public Data: any = "";
    public Updated: Date = new Date();

    public static Configurations: Configuration[] = new Array<Configuration>;

    constructor(session: Session) {
        this.Session = this.Session;
        let defaultHost = new Configuration("host", "https://eacore.azurewebsites.net");

        //TODO: Set in config: let defaultHost = new Configuration("host", "https://localhost:7039");
        System.Configurations.push(defaultHost);
    }

    static GetConfigurationByName(name: string):string {
        let result = "";
        System.Configurations.forEach(c => {
            if (c.key.toUpperCase() === name.toUpperCase())
                result = c.value;
        });
        return result;
    }
}